// import React, { useState } from 'react'
// import './verification.css'
// import * as FaIcons from 'react-icons/fa'
// import axios from 'axios'
// import { useHistory } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye, faEyeSlash, faX } from '@fortawesome/free-solid-svg-icons'

// function Verification() {
//   let history = useHistory()
//   const [emailAddress, setEmailAddress] = useState('')
//   const [code, setCode] = useState('')
//   const [password, setPassword] = useState('')
//   const [passwordShown, setPasswordShown] = useState(false)
//   const togglePassword = () => {
//     setPasswordShown(!passwordShown)
//   }

//   const checkInputs = () => {
//     const passWord1 = document.getElementById('emailInput')
//     const passWord2 = document.getElementById('password')
//     const passWord3 = document.getElementById('confirmPassword')
//     const token = document.getElementById('confirmToken')
//     const passwordValue = passWord1.value.trim()
//     const password2Value = passWord2.value.trim()
//     const codeValue = token.value.trim()
//     const password3Value = passWord3.value.trim()

//     if (passwordValue === '') {
//       setErrorFor(passWord1, "Field can't be empty")
//     } else {
//       setSuccessFor(passWord1, 'Checked')
//     }
//     if (password2Value === '') {
//       setErrorFor(passWord2, "Field can't be empty")
//     } else {
//       setSuccessFor(passWord2, 'Checked')
//     }
//     if (password3Value === '') {
//       setErrorFor(passWord3, "Field can't be empty")
//     } else if (password2Value !== password3Value) {
//       setErrorFor(passWord3, 'Passwords do not match')
//     } else {
//       setSuccessFor(passWord3, 'Checked')
//     }
//     if (codeValue === '') {
//       setErrorFor(token, "Field can't be empty")
//     } else {
//       setSuccessFor(token, 'Checked')
//     }
//     if (
//       codeValue !== '' &&
//       password2Value !== '' &&
//       password3Value !== '' &&
//       password2Value === password3Value
//     ) {
//       // window.location.href = "/"
//     }
//   }
//   function resetPassword() {
//     let item = {
//       emailAddress: emailAddress.trim(),
//       code: code.trim(),
//       password: password.trim(),
//     }
//     let gu = JSON.stringify(item)
//     axios
//       .post(
//         `https://portalapi.lifeworthhmo.com/api/Account/ResetPassword`,
//         gu,
//         { headers: { 'Content-Type': 'application/json' } }
//       )
//       .then((response) => {
//         console.debug(response)
//         history.push('/')
//       })
//       .catch((error) => console.log(error))
//     //  axios.post(`http://15.237.160.238:50/api/Account/ResetPassword` ,gu ,
//     //  { headers: { 'Content-Type': 'application/json' }})
//     //  .then((response)=>{console.debug(response)})
//   }

//   const setErrorFor = (input, message) => {
//     const formControl = input.parentElement
//     const small = formControl.querySelector('small')
//     formControl.className = 'form-control error'
//     small.innerText = message
//   }

//   const setSuccessFor = (input, message) => {
//     const formControl = input.parentElement
//     const small = formControl.querySelector('small')
//     formControl.className = 'form-control success'
//     small.innerText = message
//   }

//   const handleSubmit = (e) => {
//     e.preventDefault()
//     checkInputs()

//     resetPassword()
//     // history.push('/')
//   }

//   const handleClose = () => {
//     // navigate.push('./pendingstatus')
//     window.history.back()
//   }

//   return (
//     <article className='article'>
//       <div
//         className='cancelBackdropBtn'
//         style={{
//           position: 'absolute',
//           top: 100,
//           right: 400,
//           fontSize: '12px',
//           color: 'white',
//           cursor: 'pointer',
//           height: '30px',
//           width: '30px',
//           background: 'rgb(167, 7, 7)',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           borderRadius: '50%',
//         }}
//         onClick={handleClose}
//       >
//         <FontAwesomeIcon icon={faX} />
//       </div>

//       <form className='forrm' onSubmit={handleSubmit}>
//         <div className='form-control'>
//           {/* <label htmlFor='firstName'>Email : </label> */}
//           <input
//             type='email'
//             id='emailInput'
//             name='email'
//             onChange={(e) => setEmailAddress(e.target.value)}
//             placeholder='Email Address'
//             className='formInput'
//           />
//           <small>Error message</small>
//         </div>
//         <div className='form-control'>
//           {/* <label htmlFor='firstName'>Password : </label> */}
//           <input
//             type={passwordShown ? 'text' : 'password'}
//             id='password'
//             name='password'
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder='Password'
//             className='formInput'
//           />

//           {passwordShown ? (
//             <FontAwesomeIcon
//               icon={faEye}
//               onClick={togglePassword}
//               className='togglePassword'
//             />
//           ) : (
//             <FontAwesomeIcon
//               icon={faEyeSlash}
//               onClick={togglePassword}
//               className='togglePassword'
//             />
//           )}
//           <small>Error message</small>
//         </div>
//         <div className='form-control'>
//           {/* <label htmlFor='firstName'> Confirm Password : </label> */}
//           <input
//             type={passwordShown ? 'text' : 'password'}
//             id='confirmPassword'
//             name='password'
//             placeholder='Confirm Password'
//             onChange={(e) => setPassword(e.target.value)}
//             className='formInput'
//           />
//           {passwordShown ? (
//             <FontAwesomeIcon
//               icon={faEye}
//               onClick={togglePassword}
//               className='togglePassword'
//             />
//           ) : (
//             <FontAwesomeIcon
//               icon={faEyeSlash}
//               onClick={togglePassword}
//               className='togglePassword'
//             />
//           )}
//           <small>Error message</small>
//         </div>
//         <div className='form-control'>
//           {/* <label htmlFor='email'>Code : </label> */}
//           <input
//             id='confirmToken'
//             name='confirmToken'
//             onChange={(e) => setCode(e.target.value)}
//             placeholder='Token'
//             className='formInput'
//           />
//           <small>Error message</small>
//         </div>

//         <button type='submit' onClick={handleSubmit} className='form-btn'>
//           Create Password
//         </button>
//       </form>
//     </article>
//   )
// }

// export default Verification

import React, { useState } from 'react'
import './verification.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { useRef } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import Img1 from '../../assets/images/8812179 1.png'
import Img2 from '../../assets/images/8812189 1.png'
import Img3 from '../../assets/images/8812233 1.png'
import lifeworthLogo from '../../assets/images/lifeworthLogo.png'
import { BsEye, BsFillEyeSlashFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Verification() {
  // MISCELLANEOUS
  const toastId = useRef(null)
  const [btnDisabled, setBtnDisabled] = useState(false)
  let history = useHistory()

  // PASSWORD TOGGLE FUNCTIONALITY
  const [showPassword, setShowPassword] = useState(false)

  // USER LOGIN DETAILS
  const [user, setUser] = useState({
    emailAddress: '',
    password: '',
    confirmPassword: '',
    code: '',
  })
  // END OF USER LOGIN DETAILS

  //   FUNCTIONs FOR SETTING USER STATE

  const handleSetUser = (event, inputType) => {
    setUser({ ...user, [inputType]: event.target.value })
  }

  // FUNCTION FOR PASSWORD TOGGLE
  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev)
  }

  // END OF FUNCTION FOR PASSWORD TOGGLE

  // FUNCTION FOR ONCLICK LOGIN BUTTON
  const handleResetPassword = async (e) => {
    e.preventDefault()
    setBtnDisabled(true)

    toastId.current = toast('Please Wait', {
      autoClose: 3000,
      isLoading: true,
    })

    const correctUser = {
      emailAddress: user?.emailAddress?.trim(),
      code: user?.code.trim(),
      password: user?.password.trim(),
    }
    try {
      if (user?.password?.trim() === user?.confirmPassword?.trim()) {
        const res = await axios
          .post(
            'https://portalapi.lifeworthhmo.com/api/Account/ResetPassword',
            correctUser
          )
          .then(() => {
            toast.update(toastId.current, {
              render:
                "Details updated succesfully! You'll be redirected in a bit, Please wait...",
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            })

            setBtnDisabled(false)

            setTimeout(() => {
              history.push('/')
            }, 2500)
          })
      } else {
        setTimeout(() => {
          toast.update(toastId.current, {
            render: "Passwords don't match!",
            type: 'error',
            isLoading: false,
            autoClose: 2000,
          })
        }, 1000)
        setTimeout(() => {
          setBtnDisabled(false)
        }, 2000)
      }
    } catch (error) {
      toast.update(toastId.current, {
        type: 'error',
        autoClose: 2000,
        isLoading: false,
        render: `${
          error?.response?.data?.title ||
          error?.response?.data?.description ||
          error?.message ||
          'Something went wrong, please try again'
        }`,
      })
      setTimeout(() => {
        setBtnDisabled(false)
      }, 2000)
    }

    // login(dispatch, user, navigate)
  }
  // END OF FUNCTION FOR ONCLICK LOGIN BUTTON

  return (
    // <>
    //   <ToastContainer />
    //   <div className="verificationWrapper">
    //     <div className="verificationLeft">
    //       <div className="verificationImgWrapper img-1">
    //         <img src={Img1} alt="" className="verificationImg" />
    //       </div>
    //       <div className="verificationImgWrapper img-2">
    //         <img src={Img2} alt="" className="verificationImg" />
    //       </div>
    //       <div className="verificationImgWrapper img-3">
    //         <img src={Img3} alt="" className="verificationImg" />
    //       </div>
    //     </div>
    //     <div className="verificationRight">
    //       <div className="verificationRightMain">
    //         <form className="verification-Form" onSubmit={handleResetPassword}>
    //           <div className="verificationDesc">
    //             <div className="verificationDescMain">Set Password</div>
    //             <div className="verificationMainSub">
    //               Please enter the required details
    //             </div>
    //           </div>
    //           <div className="verification-Inputs">
    //             <label
    //               htmlFor=""
    //               className="verificationUsernameLabel verificationInputLabels"
    //             >
    //               Email
    //             </label>
    // <input
    //   type="email"
    //   className="verificationEmailInput verification-Input"
    //   placeholder="John Doe"
    //   onChange={(e) => handleSetUser(e, "emailAddress")}
    //   required
    // />
    //             <label
    //               htmlFor=""
    //               className="verificationUsernameLabel verificationInputLabels"
    //             >
    //               Code
    //             </label>
    // <input
    //   type="text"
    //   placeholder="*****"
    //   className="verificationEmailInput verification-Input"
    //   onChange={(e) => handleSetUser(e, "code")}
    //   required
    // />
    //             <label htmlFor="" className="verificationInputLabels">
    //               New Password
    //             </label>
    //             <div className="passwordWrapper">
    // <input
    //   type={showPassword ? "text" : "password"}
    //   className="verificationEmailInput verification-Input"
    //   placeholder="Password"
    //   onChange={(e) => handleSetUser(e, "password")}
    //   required
    // />
    //             </div>
    //             <label htmlFor="" className="verificationInputLabels">
    //               Confirm New Password
    //             </label>
    //             <div className="passwordWrapper">
    // <input
    //   type={showPassword ? "text" : "password"}
    //   className="verificationEmailInput verification-Input"
    //   placeholder=" Confirm password"
    //   onChange={(e) => handleSetUser(e, "confirmPassword")}
    //   required
    // />
    //             </div>
    // <div className="verificationCheckboxWrapper">
    //   <label htmlFor="" className="verificationInputLabels">
    //     Show Passwords
    //   </label>
    //   <input
    //     type="checkbox"
    //     className="verificationCheckbox"
    //     onChange={handlePasswordToggle}
    //   />
    // </div>
    //           </div>
    //           <button
    //             className="verificationBtn"
    //             type={"submit"}
    //             disabled={btnDisabled}
    //           >
    //             Confirm
    //           </button>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
      <ToastContainer />
      <div className='loginWrapper'>
        <div className='createPasswordMainContainer mainContainer'>
          <div className='mainContainerLeft'>
            <img src={lifeworthLogo} alt='Logo' className='lifeWorthLogo' />
            <br />
            <br />
            <div className='introText'>WELCOME, Provider</div>
          </div>
          <div className='mainContainerRight'>
            <form className='signInForm' onSubmit={handleResetPassword}>
              <div className='signInText'>
                {' '}
                <span>CREATE</span> Password
              </div>
              <input
                type='email'
                className='formInput'
                placeholder='Email address'
                onChange={(e) => handleSetUser(e, 'emailAddress')}
                required
              />

              <input
                type='text'
                placeholder='*****'
                className='formInput'
                onChange={(e) => handleSetUser(e, 'code')}
                required
              />

              <input
                type={showPassword ? 'text' : 'password'}
                className='formInput'
                placeholder='Password'
                onChange={(e) => handleSetUser(e, 'password')}
                required
              />

              <input
                type={showPassword ? 'text' : 'password'}
                className='formInput'
                placeholder=' Confirm password'
                onChange={(e) => handleSetUser(e, 'confirmPassword')}
                required
              />
              <div className='verificationCheckboxWrapper'>
                <label htmlFor='' className='verificationInputLabels'>
                  Show Passwords
                </label>
                <input
                  type='checkbox'
                  className='verificationCheckbox'
                  onChange={handlePasswordToggle}
                />
              </div>
              <div className='verificationBottomBtns'>
                <Link to={'/'}>
                  <div className='backToLoginPageBtn'>Cancel</div>
                </Link>
                <button
                  className='loginButton'
                  type={'submit'}
                  disabled={btnDisabled}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Verification
