import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TextField } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function AlertDialogSlide(props) {
  const {
    open,
    handleClose,
    message,
    title,
    action,
    setSignupEmail,
    isEmailValid,
    setIsEmailValid,
  } = props
  //   const [open, setOpen] = React.useState(false)

  //   const handleClickOpen = () => {
  //     setOpen(true)
  //   }

  //   const handleClose = () => {
  //     setOpen(false)
  //   }

  // HANDLE YES CLICK
  const handleCancelClick = () => {
    if (title === 'Get Signup Link') {
      action()
    }

    // navigate(link)
  }
  // END OF HANDLE YES CLICK

  // FUNCTION TO VALIDATE EMAIL
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  // END OF FUNCTION TO VALIDATE EMAIL

  // FUNCTION TO HANDLE SIGNUP EMAIL CHANGE
  const handleRejectionReasonChange = (e) => {
    setSignupEmail(e.target?.value)

    setIsEmailValid(validateEmail(e.target?.value))
  }
  // END OF FUNCTION TO HANDLE SIGNUP EMAIL CHANGE

  return (
    <div>
      {/* <Button variant='outlined' onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {message}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Email Address'
            multiline
            maxRows={3}
            type='email'
            fullWidth
            variant='standard'
            onChange={(e) => handleRejectionReasonChange(e)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCancelClick} disabled={!isEmailValid}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
