// import React, { Component } from 'react'
// import { PostData } from '../../src/services/PostData'
// import { Redirect } from 'react-router-dom'
// import Modal from './modal/Modal'
// import medi from './medicalimg.jpg'
// import './login.css'
// import RingLoader from 'react-spinners/ClipLoader'
// import * as FaIcons from 'react-icons/fa'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

// class Page extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       emailAddress: '',
//       password: '',
//       redirect: false,
//       error: null,
//       post: false,
//       data: '',
//       toggle: false,
//     }

//     this.Login = this.Login.bind(this)
//     this.togglePassword = this.togglePassword.bind(this)
//     this.onChange = this.onChange.bind(this)
//   }

//   togglePassword(e) {
//     this.setState({ toggle: !this.state.toggle })
//     console.log('clicked')
//   }

//   Login(e) {
//     this.setState({ post: true })
//     // eslint-disable-next-line react/no-direct-mutation-state
//     this.state.post = setTimeout(() => {
//       this.setState({ post: false })
//     }, 8000)
//     e.preventDefault()
//     if (this.state.emailAddress && this.state.password) {
//       PostData('Login', this.state).then((result) => {
//         let responseJSON = result

//         if (responseJSON.details) {
//           console.log(responseJSON.details)
// sessionStorage.setItem('token', responseJSON.token)
// sessionStorage.setItem('id', responseJSON.details.idProvider)
// sessionStorage.setItem(
//   'providername',
//   responseJSON.details.providerName
// )
//           this.setState({ redirect: true })
//         } else {
//           console.log('login error')
//           this.setState({ error: 'Wrong Login details !' })
//           this.setState({ data: result })
//         }
//       })
//     }
//   }

//   onChange(e) {
//     this.setState({ [e.target.name]: e.target.value })
//   }

//   render() {
//     if (this.state.redirect) {
//       return <Redirect to={'/dash'} />
//     }

//     if (sessionStorage.getItem('token')) {
//       return <Redirect to={'/dash'} />
//     }

//     return (
//       <section className='form'>
//         <Images />
//         <div className='formm'>
//           {this.state.post ? <RingLoader></RingLoader> : ``}
//           <form>
//             <span className='er'>{this.state.error} </span>

//             <h2>Email</h2>
//             <label htmlFor='email'></label>
//             <input
//               type='email'
//               name='emailAddress'
//               placeholder='Email'
//               className='emailInput'
//               onChange={this.onChange}
//             />
//             <h2>PASSWORD</h2>
//             <div className='password'>
//               <label htmlFor='password'></label>
//               <input
//                 type={this.state.toggle ? 'text' : 'password'}
//                 placeholder='Password'
//                 name='password'
//                 className='passwordInput'
//                 onChange={this.onChange}
//               />
//               {this.state.toggle ? (
//                 <FontAwesomeIcon
//                   icon={faEye}
//                   onClick={this.togglePassword}
//                   className='togglePassword'
//                 />
//               ) : (
//                 <FontAwesomeIcon
//                   icon={faEyeSlash}
//                   onClick={this.togglePassword}
//                   className='togglePassword'
//                 />
//               )}
//             </div>

//             <button type='submit' className='btn1' onClick={this.Login}>
//               Login
//             </button>

//             <p className='desc'>
//               Dont have an account yet ?
//               <Modal />
//             </p>
//           </form>
//         </div>
//       </section>
//     )
//   }
// }
// const Images = () => {
//   return <img src={medi} alt='' className='img' />
// }

// export default Page

import React, { useState } from 'react'
import './login.css'
import Img1 from '../assets/images/8812179 1.png'
import Img2 from '../assets/images/8812189 1.png'
import Img3 from '../assets/images/8812233 1.png'
import lifeworthLogo from '../assets/images/lifeworthLogo.png'
import lwMiniLogo from '../assets/images/LWlogo2.png'
import { BsEye, BsFillEyeSlashFill } from 'react-icons/bs'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRef } from 'react'
import AlertDialogSlide from '../components/Dialogue'
import medi from './medicalimg.jpg'

const Page = () => {
  // MISCELLANEOUS
  const toastId = useRef(null)
  let history = useHistory()
  const [btnDisabled, setBtnDisabled] = useState(false)

  // BACKDROP
  const [open, setOpen] = React.useState(false)

  // USER LOGIN DETAILS
  const [user, setUser] = useState({
    emailAddress: '',
    password: '',
  })

  // SIGNUP EMAIL
  const [signupEmail, setSignupEmail] = useState('')

  // EMAIL VALIDITY
  const [isEmailValid, setIsEmailValid] = useState(false)

  // PASSWORD VISIBILITY
  const [showPassword, setShowPassword] = useState(false)

  // FUNCTION FOR PASSWORD TOGGLE
  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev)
  }

  // END OF FUNCTION FOR PASSWORD TOGGLE

  // SET USER INFO FUNCTION
  const handleSetUser = (event, inputType) => {
    setUser({ ...user, [inputType]: event.target.value })
  }
  // END OF SET USER INFO FUNCTION

  //  FUNCTION TO HANDLE USER LOGIN
  const handleLogin = async (e) => {
    e.preventDefault()
    toastId.current = toast('Please wait...', {
      autoClose: false,
      isLoading: true,
    })
    setBtnDisabled(true)

    try {
      await axios
        .post(`https://portalapi.lifeworthhmo.com/api/Login`, user)
        .then((res) => {
          toast.update(toastId.current, {
            render: 'Login succesful! Please wait while we redirect you.',
            type: 'success',
            autoClose: 2000,
            isLoading: false,
          })
          setBtnDisabled(false)
          sessionStorage.setItem('token', res?.data?.token)
          sessionStorage.setItem('id', res?.data?.details.idProvider)
          sessionStorage.setItem(
            'providername',
            res?.data?.details.providerName
          )

          history.push('/dash')
        })
      // .then(() => {
      //   console.log('a')

      //   return
      // })
    } catch (error) {
      console.log(error)
      console.log(error.message)
      // dispatch(loginFailure())
      toast.update(toastId.current, {
        type: 'error',
        autoClose: 2000,
        isLoading: false,
        render: `${
          error?.response?.data?.title ||
          error?.response?.data?.description ||
          error?.message ||
          'Something went wrong, please try again'
        }`,
      })
      setTimeout(() => {
        setBtnDisabled(false)
      }, 2000)
    }
  }

  //   END OF FUNCTION TO HANDLE USER LOGIN

  // SHOW BACKDROP

  const handleClickOpen = () => {
    setOpen(true)
  }

  // END OF SHOW BACKDROP

  // HIDE BACKDROP

  const handleClose = () => {
    setOpen(false)
  }

  // END OF HIDE BACKDROP

  // FUNCTION TO HANDLE TOKEN REQUEST
  const getToken = async () => {
    toastId.current = toast('Please wait...', {
      autoClose: 3000,
      isLoading: true,
    })
    try {
      if (signupEmail) {
        await axios
          .post(
            'https://portalapi.lifeworthhmo.com/api/Account/RequestToken',
            signupEmail?.trim()
          )
          .then(() => {
            toast.update(toastId.current, {
              render: 'Signup link sent successfully!',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            })
          })
      }
    } catch (error) {
      console.log({ error })
      console.log(error.response)
      toast.update(toastId.current, {
        type: 'error',
        autoClose: 3000,
        isLoading: false,
        render: `${
          error?.response?.data?.title ||
          error?.response?.data?.description ||
          error?.message ||
          'Something went wrong, please try again'
        }`,
      })
    }
  }
  // END OF FUNCTION TO HANDLE TOKEN REQUEST

  return (
    // <>
    //   <ToastContainer />
    //   <div className="loginWrapper">
    // <AlertDialogSlide
    //   open={open}
    //   handleClose={handleClose}
    //   message="Hi there! Please provide your email address to initiate the onboarding process. You will receive a confirmation email to proceed further"
    //   title="Get Signup Link"
    //   action={getToken}
    //   setSignupEmail={setSignupEmail}
    //   isEmailValid={isEmailValid}
    //   setIsEmailValid={setIsEmailValid}
    // />
    //     <div className="loginLeft">
    //       <img src={medi} alt="bg image" />

    //     </div>
    //     <div className="loginRight">
    //       <div className="loginRightMain">
    //         <form className="login-Form" onSubmit={handleLogin}>
    //           <div className="loginDesc">
    //             <div className="loginDescMain">Log in</div>
    //             <div className="loginMainSub">
    //               Please enter your login credentials
    //             </div>
    //           </div>
    // <div className="login-Inputs">
    //   <label htmlFor="" className="loginUsernameLabel">
    //     Username
    //   </label>
    //   <input
    //     type="text"
    //     className="loginEmailInput login-Input"
    //     placeholder="John Doe"
    //     onChange={(e) => handleSetUser(e, "emailAddress")}
    //     required
    //   />
    //   <label htmlFor="" className="loginPasswordLabel">
    //     Password
    //   </label>
    //   <div className="passwordWrapper">
    //     <input
    //       type={showPassword ? "text" : "password"}
    //       className="loginPassword-Input login-Input"
    //       placeholder="Password"
    //       onChange={(e) => handleSetUser(e, "password")}
    //       required
    //     />
    //     <span
    //       onClick={handlePasswordToggle}
    //       className="passwordToggleIcon"
    //     >
    //       {showPassword ? <BsEye /> : <BsFillEyeSlashFill />}
    //     </span>
    //   </div>
    // <p className="noAccountYet">
    //   Don't have an account yet?{" "}
    //   <span className="createOne" onClick={handleClickOpen}>
    //     Create one
    //   </span>
    // </p>
    //           </div>
    // <button
    //   className="loginBtn"
    //   type={"submit"}
    //   disabled={btnDisabled}
    // >
    //   Login
    // </button>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
      <ToastContainer />
      <AlertDialogSlide
        open={open}
        handleClose={handleClose}
        message='Hi there! Please provide your email address to initiate the onboarding process. You will receive a confirmation email to proceed further'
        title='Get Signup Link'
        action={getToken}
        setSignupEmail={setSignupEmail}
        isEmailValid={isEmailValid}
        setIsEmailValid={setIsEmailValid}
      />
      <div className='loginWrapper'>
        <div className='mainContainer'>
          <div className='mainContainerLeft'>
            <img src={lifeworthLogo} alt='Logo' className='lifeWorthLogo' />
            <br />
            <br />
            <div className='introText'> Dear Provider, WELCOME</div>
          </div>
          <div className='mainContainerRight'>
            <form className='signInForm' onSubmit={handleLogin}>
              <div className='signInText'>
                {' '}
                <span>Sign in</span> to your account
              </div>
              <input
                type='text'
                className='formInput'
                placeholder='Email address'
                onChange={(e) => handleSetUser(e, 'emailAddress')}
                required
              />

              <div className='passwordWrapper'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className='formInput'
                  placeholder='Password'
                  onChange={(e) => handleSetUser(e, 'password')}
                  required
                />
                <span
                  onClick={handlePasswordToggle}
                  className='passwordToggleIcon'
                >
                  {showPassword ? <BsEye /> : <BsFillEyeSlashFill />}
                </span>
              </div>
              <p className='noAccount'>
                Don't have an account yet?{' '}
                <span className='createOne' onClick={handleClickOpen}>
                  Create one
                </span>
              </p>

              <button
                className='loginButton'
                type={'submit'}
                disabled={btnDisabled}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page
