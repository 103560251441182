import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import "./claims.css";
import { useForm } from "react-hook-form";
import { DebounceInput } from "react-debounce-input";
import "../Claims/authorization/authorization.css";
import "../Claims/Medicals/medical.css";
import "../Claims/details/details.css";
// import toast, { Toaster } from 'react-hot-toast'
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";
import Navbar from "../sidebar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import useComponentVisible from "../hooks/useComponentVisible";
import { useDispatch, useSelector } from "react-redux";
import { accumulateTariff } from "../redux/patientsSlice";
import AutoComplete from "../components/autoComplete/AutoComplete";
import Virtualize from "../components/autoComplete/AutoCompleteVirtual";
import { TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

function Claims() {
  let navigate = useHistory();
  let claims = [];
  let date = new Date().getFullYear();
  const [option, setOption] = useState([]);
  const [desc, setDesc] = useState("");
  const [options, setOptions] = useState(0);
  const [content, setContent] = useState("");
  const [apiDataMedical, setApiDataMedical] = useState([]);
  const [searchInput, setSearchInput] = useState({});
  const [searchEmployee, setSearchEmployee] = useState("");
  const [apiData, setApiData] = useState([]);
  const [apiData2, setApiData2] = useState([]);
  const [amountCalc, setAmountCalc] = useState(0);
  const [apiDataAuthor, setApiDataAuthor] = useState([]);
  const [codes, setCodes] = useState("");
  const [type, setType] = useState("");
  const [proType] = useState("str");
  const [dates, setDates] = useState("");
  const [claimsList, setClaimsList] = useState([]);
  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [filteredList, setFilteredList] = useState([]);
  const [filteredList2, setFilteredList2] = useState([]);
  const [filteredDiagList, setFilteredDiagList] = useState([]);
  const [filteredTariffList, setFilteredTariffList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showListNo, setShowListNo] = useState(false);
  const [showListDiag, setShowListDiag] = useState(false);
  const [showTariff, setShowTariff] = useState(false);
  const [selectedName, setselectedName] = useState(null);
  const [selectedNo, setselectedNo] = useState(null);
  const [tariff, setTariff] = useState(null);
  const [selectedTariff, setselectedTariff] = useState(null);
  const [enrolee, setEnrolee] = useState(null);
  const [searchParam, setsearchParam] = useState("");
  const [showTable, setshowTable] = useState(false);
  const [selectedLife, setSelectedLife] = useState("private");

  const [selectedStatus, setSelectedStatus] = useState("principal");
  const [loading, setLoading] = useState(false);
  const [loadingNames, setLoadingNames] = useState(false);
  const [claimType, setClaimType] = useState("");
  const [page, setPage] = useState(1);
  const [enroleeData, setEnroleeData] = useState(null);
  const [submitClaimButtonStatus, setSubmitClaimButtonStatus] = useState(false);

  const [dateValue, setDateValue] = useState(new Date());

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const providerId = Number(sessionStorage.getItem("id"));
  const providerName = sessionStorage.getItem("providername");

  let filtered;
  const [url, setUrl] = useState(
    `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&FullName=${searchParam}`
  );

  // uncomment
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    axios
      .get(url, { cancelToken: cancelToken.token })
      .then((response) => {
        setApiData(response.data);
        setApiData2(response.data);

        setLoading(false);
        setLoadingNames(false);
        console.log("plasch");

        setType("Principal");
        if (response.data.length >= 1) {
          toast.info("Enrolee(s) Fetched Succesfully", {
            autoClose: 2500,
          });
          console.log(response.data);
          setClaimType(response.data[0]?.type || selectedLife);
        } else {
          toast.info("Enrolee(s) NOT FOUND", {
            autoClose: 2500,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    return () => {
      cancelToken.cancel();
    };
  }, [url, selectedLife]);

  const searchEmployeeNumber = (searchId) => {
    setSearchEmployee(searchId);
    if (searchId.includes("~")) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Dependant?idProvider=${providerId}&DependantNumber=${searchId}`
        )
        .then((response) => {
          setApiData(response.data);
          setType("Dependant");
          if (response.data.length >= 1) {
            toast.info("Enrolee(s) Fetched Succesfully", {
              autoClose: 2500,
            });
          } else {
            toast.info("Enrolee(s) NOT FOUND", {
              autoClose: 2500,
            });
          }
        });
    } else {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&EmployeeNumber=${searchId}`
        )
        .then((response) => {
          setApiData(response.data);
          setType("Principal");
          if (response.data.length >= 1) {
            toast.info("Enrolee(s) Fetched Succesfully", {
              autoClose: 2500,
            });
          } else {
            toast.info("Enrolee(s) NOT FOUND", {
              autoClose: 2500,
            });
          }
        });
    }
  };

  const handleSelect = (e, selectedItem, type) => {
    if (type === "name") {
      // setselectedName(e.target.textContent);
      setselectedName(
        selectedItem.surname +
          "  " +
          (selectedItem.name
            ? selectedItem.name.toLowerCase()
            : selectedItem.fullName.toLowerCase())
      );
      setShowList(false);
      setIsComponentVisible(false);

      setselectedNo(selectedItem.employeeNo);
    }
    if (type === "employeeNo") {
      // setselectedNo(e.target.textContent.split(" ")[0]);
      setselectedNo(selectedItem.employeeNo);
      setselectedName(
        selectedItem?.surname +
          "  " +
          (selectedItem.name
            ? selectedItem.name.toLowerCase()
            : selectedItem.fullName.toLowerCase())
      );
      setShowListNo(false);
      setIsComponentVisible(false);
    }
    // setContent(event.target.value);
    setContent(selectedItem.employeeNo);
    if (content.includes("~")) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Dependant?idProvider=${providerId}&DependantNumber=${content}`
        )
        .then((response) => {
          setOption(...response.data);
          setType("Dependant");
        });
    } else if (content) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&EmployeeNumber=${content}`
        )
        .then((response) => {
          setType("Principal");
          setOption(...response.data);
        });
    }
  };
  // console.log(content);
  const Medical = useCallback(async () => {
    const cancelToken = axios.CancelToken.source();

    if (providerName) {
      // .get(
      // `https://portalapi.lifeworthhmo.com/api/Tarrif/provider/${providerName}?claimType=${claimType}&page=${page}&limit=500`,
      //   // `https://portalapi.lifeworthhmo.com/api/Tarrif/provider/${providerName}`,
      //   {
      //     cancelToken: cancelToken.token,
      //   }
      // )

      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Tarrif/provider/${providerName}`,
          // `https://portalapi.lifeworthhmo.com/api/Tarrif/provider/${providerName}?claimType=${claimType}&page=${page}&limit=5000`,
          console.log(providerName), // Debugging step to ensure providerName is correct

          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          console.log(response?.data);
          setLoading(false);
          setApiDataMedical(response.data);

          // if (apiDataMedical) {
          //   toast.info('Tariffs Fetched Succesfully', {
          //     // toast.info('Classifications Fetched Succesfully', {
          //     autoClose: 2500,
          //     style: {
          //       borderRadius: '10px',
          //       background: '#F8A370',
          //       color: '#fff',
          //     },
          //   })
          // }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
      return () => {
        cancelToken.cancel();
      };
    }
  }, [claimType, providerName, page]);

  const Description = (e, singleTariff) => {
    // console.log("hhh", apiDataMedical, e.target.value);
    // console.log("aa");
    setTariff(singleTariff.description);
    setselectedTariff(singleTariff);
    setShowTariff(false);
    setIsComponentVisible(false);
    if (desc === "") {
      alert("Select a Diagnosis");
    } else {
      const price = apiDataMedical
        .filter((data) => data.description === singleTariff.description)
        // .filter((data) => data.description === e.target.value)
        .map((data) => data.price);
      setOptions(...price);
      if (options) {
        toast.info("Unit Price Fetched Succesfully", {});
      }
    }
  };

  const chargesApproved = () => {
    const value = Number(document.getElementById("charges").value);
    const calcAmount = value * options;
    setAmountCalc(calcAmount);
  };
  const getDate = () => {
    const dateNew = new Date();
    const day = dateNew.getDate();
    const month = dateNew.getMonth() + 1;
    const year = dateNew.getFullYear();
    setDay(day);
    setMonth(month);
    setYear(year);
  };

  const getDateConsul = (e) => {
    getDate();
    if (e.target.value !== "") {
      const dateConvert = e.target.value;
      const dateStr = new Date(dateConvert);

      const iso = dateStr.toISOString();
      setDateValue(iso);

      setDates(iso);
    }
  };

  const backClick = () => {
    navigate.push("./dash");
  };

  const reload = () => {
    window.location.reload(false);
  };

  const getAuthorCode = (e) => {
    setCodes(e.target.value);
  };
  const defaultValues = {
    chargesSent: amountCalc,
    authorcode: codes,
    consultancyDate: "",
    qty: 0,
    diagnosis: "",
    details: "",
    idProvider: providerId,
    type: type,
    protype: proType,
    TreatmentDate: dates,
    Day: day,
    Month: month,
    unitPrice: options,
    claimType: claimType,
    procedure: "",
    idProduct: "",
    userId: "",
    comment: "",
    consultancyFee: 0,
  };
  const { register, handleSubmit, reset } = useForm({ defaultValues });

  const addUp = (data) => {
    data.classification = desc || data.diagnosis;
    data.type = type;
    data.chargesSent = amountCalc;
    data.chargesApproved = amountCalc;
    data.idProvider = providerId;
    data.idCompany = providerId;
    data.employeeNo = option.employeeNo || selectedNo;
    data.employeeName =
      selectedName || option.surname + " " + (option.fullName || option.name);
    data.employeeSurname = option.surname || selectedName?.split(" ")[0];
    data.consultancyDate = dateValue;
    data.TreatmentDate = dateValue;
    data.Day = day;
    data.Month = month;
    data.year = year;
    data.unitPrice = options;
    data.approvedQty = data.qty;
    data.diagnosis = desc;
    data.Description = selectedTariff.description;
    data.authdate = new Date().toISOString();
    data.claimType = claimType;
    data.authorcode = codes;

    const dataInfo = {
      day: data.Day,
      description: data.Description,
      month: data.Month,
      treatmentDate: data.TreatmentDate,
      authDate: data.authdate,
      authorcode: data.authorcode,
      chargesSent: data.chargesSent,
      chargesApproved: data.chargesSent,
      classification: data.diagnosis,
      consultancyDate: data.consultancyDate,
      details: data.details,
      diagnosis: data.diagnosis,
      employeeName: data.employeeName,
      employeeSurname: data.employeeSurname,
      employeeNo: data.employeeNo,
      idCompany: data.idCompany,
      idProvider: data.idProvider,
      proType: data.protype,
      qty: data.qty,
      approvedQty: data.qty,
      type: data.type,
      unitPrice: data.unitPrice,
      year: data.year,
      claimType: data.claimType,
      procedure: "",
      idProduct: "",
      userId: "",
      comment: "",
      consultancyFee: 0,
    };

    claims.push(dataInfo);

    setClaimsList((prev) => prev.concat(dataInfo));

    //  setClaimsList((prevstate)=>{...prevstate, claims})

    //  if (claims.length >= 0){
    //   //  onSubmit()
    //  }
    // setApiDataMedical(null);
    setAmountCalc("");
    setOptions("");
    reset(defaultValues);
    setTariff("");
    // setDesc('')
  };

  const onSubmit = () => {
    console.log(claimsList);
    let answer = window.confirm(`You are about to submit a Claim`);
    if (answer) {
      setSubmitClaimButtonStatus(true);
      axios
        .post("https://portalapi.lifeworthhmo.com/api/Claims", claimsList, {
          headers: { "Content-Type": "application/json", accept: "*/*" },
        })
        .then(() => {
          toast.info("Claims Submitted Succesfully", {
            autoClose: 2500,
          });
          setTimeout(() => window.location.reload(), 1800);
        })
        .catch((error) => {
          setSubmitClaimButtonStatus(false);
          toast.error(error.message);
          console.error(error);
          console.log(error);
        });
    }
  };
  // console.log(claimsList);
  const totalBilled = claimsList.map((data) => data.chargesSent);
  // console.log(totalBilled);
  const totalBilledAmount = totalBilled.reduce((a, b) => a + b, 0);
  // console.log(totalBilledAmount);
  // const code = claimsList.map((data)=>data.authorcode)
  // console.log(code);

  const handleFilterEnrolees = (e, filterParam) => {
    if (filterParam === "name") {
      setFilteredList(apiData);
      setselectedName(e.target.value);
      if (!showList) {
        setShowList(true);
      }
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }
      filtered = apiData.filter(
        (listItem) =>
          (listItem.name
            ? listItem.name
                .toUpperCase()
                .includes(`${e.target.value.toUpperCase()}`)
            : listItem.fullName
                .toUpperCase()
                .includes(`${e.target.value.toUpperCase()}`)) ||
          listItem.surname.includes(`${e.target.value.toUpperCase()}`)
      );

      setFilteredList(filtered);
    }
    if (filterParam === "number") {
      setselectedNo(e.target.value);

      if (!showListNo) {
        setShowListNo(true);
      }
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }
      filtered = apiData.filter((listItem) =>
        listItem.employeeNo.includes(`${e.target.value.toUpperCase()}`)
      );

      setFilteredList(filtered);
    }
    if (filterParam === "diagnosis") {
      setDesc(e.target.value);
      // setShowListNo(false);
      if (!showListDiag) {
        setShowListDiag(true);
      }
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }

      filtered = apiDataAuthor.filter((listItem) => {
        // console.log(listItem);
        return listItem.diagnosis
          .toUpperCase()
          .includes(`${e.target.value.toUpperCase()}`);
      });

      setFilteredDiagList(filtered);
      // console.log(filtered);
    }
    if (filterParam === "tariff") {
      setFilteredTariffList(apiDataMedical);
      setTariff(e.target.value);
      // setShowListNo(false);
      if (!showTariff) {
        setShowTariff(true);
      }
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }

      filtered = apiDataMedical.filter((listItem) => {
        return listItem.description
          .toUpperCase()
          .split(" ")
          .join("")
          .includes(`${e.target.value.toUpperCase().split(" ").join("")}`);
      });

      setFilteredTariffList(filtered);
      // console.log(filtered);
    }
  };
  const handleFilterEnrolees2 = (e) => {
    setsearchParam(e.target.value);
    setselectedName(e.target.value);
    // setLoadingNames(true)
    setFilteredList2(apiData2);
    // if (showList) {
    //   setShowList(false)
    // }
  };

  const handleShowList = (filterType) => {
    if (filterType === "name") {
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }
      setShowList(!showList);

      setShowTariff(false);
      setFilteredList(apiData);
      setShowListNo(false);
      setShowListDiag(false);
    }
    if (filterType === "number") {
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }
      setShowListNo(!showListNo);
      setShowTariff(false);
      setFilteredList(apiData);
      setShowList(false);
      setShowListDiag(false);
    }
    if (filterType === "diagnosis") {
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }
      setShowListDiag(!showListDiag);
      setShowTariff(false);
      setShowListNo(false);
      setFilteredDiagList(apiDataAuthor);
      setShowList(false);
    }
    if (filterType === "tariff") {
      if (!isComponentVisible) {
        setIsComponentVisible(true);
      }
      setShowTariff(!showTariff);
      setShowListDiag(false);
      setShowListNo(false);
      setFilteredTariffList(apiDataMedical);
      setShowList(false);
    }
  };
  const handleShowList2 = (filterType) => {
    if (filterType === "name") {
      // setShowList(!showList)
      setShowTariff(false);
      setFilteredList(apiData);
      setShowListNo(false);
      setShowListDiag(false);
    }
    if (filterType === "number") {
      setShowListNo(!showListNo);
      setShowTariff(false);
      setFilteredList(apiData);
      setShowList(false);
      setShowListDiag(false);
    }
    if (filterType === "diagnosis") {
      setShowListDiag(!showListDiag);
      setShowTariff(false);
      setShowListNo(false);
      setFilteredDiagList(apiDataAuthor);
      setShowList(false);
    }
    if (filterType === "tariff") {
      setShowTariff(!showTariff);
      setShowListDiag(false);
      setShowListNo(false);
      setFilteredTariffList(apiDataMedical);
      setShowList(false);
    }
  };

  const allEnrolees = useMemo(() => {
    // Diagnosis();
    // searchItems(providerId);
    // Description();
  }, [providerId]);
  // console.log(allEnrolees);

  const handleSelectedDiag = (diagnosis) => {
    setDesc(diagnosis.diagnosis);
    setShowListDiag(false);
  };

  useEffect(() => {
    // searchItems()
    // Diagnosis()
    const cancelToken = axios.CancelToken.source();
    Medical();
    axios
      .get(`https://portalapi.lifeworthhmo.com/api/Diagnosis`, {
        cancelToken: cancelToken.token,
      })
      .then((response) => {
        // console.log("res");
        setApiDataAuthor(response.data);

        // if(apiDataAuthor){
        //  toast.info("Diagnosis Fetched Succesfully", {
        //     autoClose: 2500,
        //     style: {
        //     borderRadius: '10px',
        //     background: '#F8A370',
        //     color: '#fff',
        //  },
        //   },
        //   )
        // }
      })
      // .then(() => {
      //   setDesc(e.target.value);
      //   Medical();
      // })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const handleShowTable = () => {
    setshowTable(true);
    // addUp()
  };

  const handleSelectedValues = useCallback(() => {
    if (selectedStatus === "principal" && selectedLife === "private") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&FullName=${searchParam}`
      );
      // uncomment
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedStatus === "dependant" && selectedLife === "private") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Dependant?IdProvider=${providerId}&FullName=${searchParam}`
      );
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedLife === "nhis" && selectedStatus === "dependant") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Dependant/nhis?IdProvider=${providerId}&FullName=${searchParam}`
      );

      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedLife === "nhis" && selectedStatus === "principal") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Employee/nihs?IdProvider=${providerId}&FullName=${searchParam}`
      );
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedStatus === "principal" && selectedLife === "plaschema") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&FullName=${searchParam}`
      );
      // uncomment
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedStatus === "dependant" && selectedLife === "plaschema") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Dependant?IdProvider=${providerId}&FullName=${searchParam}`
      );
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
  }, [providerId, searchParam, selectedLife, selectedStatus]);

  const handleSelectProviderOption = (e) => {
    setselectedNo("");
    setselectedName("");
    setTariff("");
    // Uncomment tthis to go back to default
    setLoading(true);
    setSelectedLife(e.target.value);
    // handleSelectedValues()
  };
  const handleSelectEnroleeOption = (e) => {
    setselectedNo("");
    setselectedName("");
    setTariff("");
    // Uncomment tthis to go back to default

    setLoading(true);
    setSelectedStatus(e.target.value);
    // handleSelectedValues()
  };

  const handleSetLife = (e, lifeType) => {
    setselectedNo("");
    setselectedName("");
    setTariff("");
    setLoading(true);

    if (
      lifeType === "private" ||
      lifeType === "nhis" ||
      lifeType === "plaschema"
    ) {
      setSelectedLife(lifeType);
    }
    if (lifeType === "principal" || lifeType === "dependant") {
      setSelectedStatus(lifeType);
    }

    if (selectedLife === "private" && selectedStatus === "principal") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&FullName=${searchParam}`
      );
      // setSelectedLife(lifeType)
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }

    if (selectedLife === "private" && selectedStatus === "dependant") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Dependant?IdProvider=${providerId}&FullName=${searchParam}`
      );

      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }

    if (selectedLife === "plaschema" && selectedStatus === "principal") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&FullName=${searchParam}`
      );
      // setSelectedLife(lifeType)
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedLife === "plaschema" && selectedStatus === "dependant") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Dependant?IdProvider=${providerId}&FullName=${searchParam}`
      );

      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedLife === "nhis" && selectedStatus === "dependant") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Dependant/nhis?IdProvider=${providerId}&FullName=${searchParam}`
      );
      // setSelectedLife(lifeType)
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    if (selectedLife === "nhis" && selectedStatus === "principal") {
      setUrl(
        `https://portalapi.lifeworthhmo.com/api/Employee/nihs?IdProvider=${providerId}&FullName=${searchParam}`
      );
      // setSelectedLife(lifeType)
      setShowList(false);
      setShowListNo(false);
      setShowTariff(false);
    }
    // if (lifeType === 'private') {
    //   setUrl(
    //     `https://portalapi.lifeworthhmo.com/api/Employee?IdProvider=${providerId}&FullName=${searchParam}`
    //   )
    //   setSelectedLife(lifeType)
    //   setShowList(false)
    //   setShowListNo(false)
    //   setShowTariff(false)
    // }
    // if (lifeType === 'nhis') {
    //   setUrl(
    //     `https://portalapi.lifeworthhmo.com/api/Employee/nihs?IdProvider=${providerId}&FullName=${searchParam}`
    //   )

    //   setSelectedLife(lifeType)
    //   setShowList(false)
    //   setShowListNo(false)
    //   setShowTariff(false)
    // }
  };

  const handleDeleteClaim = (claimData) => {
    setClaimsList(
      claimsList.filter((claim) => claim.authDate !== claimData.authDate)
    );
  };

  const handleKeyDown = (event) => {
    // console.log(`search param ${searchParam}`)
    const cancelToken = axios.CancelToken.source();

    axios.get(url, { cancelToken: cancelToken.token }).then((response) => {
      setApiData2(response.data);
      return () => {
        cancelToken.cancel();
      };
    });
    setShowList(true);
    setLoadingNames(true);
    event.preventDefault();
    handleSelectedValues();
  };

  const setPageNumber = (actionType) => {
    if (actionType === "prev") {
      page > 1 && setPage(page - 1);
    }
    if (actionType === "next") {
      apiDataMedical.length > 1 && setPage(page + 1);
    }
  };
  // comment

  useEffect(() => {}, [loading, searchParam, showList]);
  // useEffect(() => {
  //   console.log(selectedLife, selectedStatus)
  // }, [selectedLife, selectedStatus])

  //  Uncomment this to go back to default

  useEffect(() => {
    handleSelectedValues();
  }, [selectedLife, selectedStatus, handleSelectedValues]);

  useEffect(() => {
    Medical();
  }, [claimType, Medical, page]);

  useEffect(() => {}, [page]);

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  useEffect(() => {}, [dateValue]);

  return (
    <>
      <div className="addClaimsWrapper">
        <Navbar />
        <section className="claims-wrapper">
          {/* <div className='typeWrapper'> */}

          {/* </div> */}
          <div>
            {/* <form> */}
            <form
              onSubmit={handleSubmit(addUp)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <div className="">
                <div className="employeeDetails">
                  <div className="heading">
                    <h2>New Claim</h2>

                    <div>
                      <button
                        onClick={backClick}
                        className="addClaimsReloadBack"
                      >
                        Back to Dashboard
                      </button>
                      <button onClick={reload} className="addClaimsReload">
                        Reload
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                  <div className="employeeDetailsWrapper">
                    <div className="employeeDetailsLeft">
                      <label
                        for="providerClassification"
                        className="selectLabel"
                      >
                        Select Provider Classification:
                      </label>
                      <select
                        name="providerClassification"
                        id="providerClassification"
                        className="providerClassification"
                        onChange={(e) => handleSelectProviderOption(e)}
                      >
                        <option
                          value="private"
                          // onClick={(e) => handleSetLife(e, 'private')}
                        >
                          Private
                        </option>
                        <option
                          value="nhis"
                          // onClick={(e) => handleSetLife(e, 'nhis')}
                        >
                          NHIS
                        </option>
                        <option
                          value="plaschema"
                          // onClick={(e) => handleSetLife(e, 'private')}
                        >
                          Plaschema
                        </option>
                      </select>
                      <br />
                      <br />

                      <label
                        for="enroleeClassification"
                        className="selectLabel"
                      >
                        Select Enrolee Classification:
                      </label>
                      <select
                        name="enroleeClassification"
                        id="enroleeClassification"
                        className="enroleeClassification"
                        onChange={(e) => handleSelectEnroleeOption(e)}
                      >
                        <option value="principal" className="option">
                          Principal
                        </option>

                        <option value="dependant">Dependant</option>
                      </select>
                      <br />
                      <br />
                      <div className="enroleeListRelativeNo">
                        <div className="EnroleeNumberTitle">
                          Enrolee Name or Enrolee Id *
                        </div>

                        <AutoComplete
                          data={apiData}
                          title="Enrolee Name or Number"
                          setselectedName={setselectedName}
                          setselectedNo={setselectedNo}
                          setDesc={setDesc}
                          Description={Description}
                          setEnroleeData={setEnroleeData}
                        />
                      </div>

                      <div className="enroleeListRelative"></div>

                      <div className="diagnosis">
                        <div className="diagnosisTitle">Diagnosis *</div>
                        <AutoComplete
                          data={apiDataAuthor}
                          title="Diagnosis"
                          setselectedName={setselectedName}
                          setselectedNo={setselectedNo}
                          setDesc={setDesc}
                          Description={Description}
                          required
                        />
                      </div>

                      <div className="encounterDateTitle">Encounter Date</div>
                      <input
                        type="date"
                        className="encounterDateInput"
                        onChange={(e) => getDateConsul(e)}
                        // {...register('consultancyDate')}
                        required
                      />
                      <div className="encounterDateTitle">
                        Authorization Code
                      </div>
                      <input
                        type="text"
                        className="encounterDateInput"
                        onChange={(e) => getAuthorCode(e)}
                        // {...register('consultancyDate')}
                      />
                    </div>
                    {enroleeData && (
                      <div className="patientDetailsWrapper">
                        <div className="patientDetailsWrapperTop">
                          <img
                            src={`https://www.firsthmo.com/Pics/${enroleeData?.imageFileName}`}
                            alt=""
                          />
                        </div>
                        <div className="patientDetailsWrapperBottom">
                          <div className="enroleeName enroleeTitle">
                            Name:
                            <span>
                              {" "}
                              {enroleeData?.surname +
                                "  " +
                                (enroleeData?.name
                                  ? enroleeData?.name?.toLowerCase()
                                  : enroleeData?.fullName?.toLowerCase())}
                            </span>
                          </div>
                          <div className="enroleeType enroleeTitle">
                            Enrolee Type:{" "}
                            <span>
                              {enroleeData?.employeeNo.includes("~")
                                ? "Dependant"
                                : "Principal"}
                            </span>
                          </div>
                          <div className="enroleeStatus enroleeTitle">
                            Status:{" "}
                            <span>
                              {enroleeData?.statusId === "XX"
                                ? "Inactive"
                                : "Active"}
                            </span>
                          </div>
                          <div className="enroleePhone enroleeTitle">
                            Phone No:{" "}
                            <span>
                              {enroleeData?.phoneNo
                                ? enroleeData.phoneNo
                                : "Null"}
                            </span>
                          </div>
                          <div className="enroleePhone enroleeTitle">
                            Age:{" "}
                            <span>
                              {new Date().getFullYear() -
                                new Date(
                                  enroleeData?.birthDate ||
                                    enroleeData?.dateOfBrith
                                ).getFullYear()}
                            </span>
                          </div>
                          <div className="enroleePhone enroleeTitle">
                            Gender:{" "}
                            <span>
                              {enroleeData?.sex === "F" && "Female"}
                              {enroleeData?.sex === "M" && "Male"}
                              {!enroleeData?.sex && "Null"}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="section-1">
                  <div className="classification-desc">
                    <div className="descTitle">Service/tariff item:</div>

                    <input
                      className="descInput"
                      onClick={() => handleShowList("tariff")}
                      onChange={(e) => handleFilterEnrolees(e, "tariff")}
                      value={tariff}
                      disabled={loading}
                      placeholder={loading ? "Loading... Please wait" : ""}
                      required
                    />

                    {isComponentVisible && (
                      <div className="serviceContainer" ref={ref}>
                        {filteredTariffList?.map((singleTariff) => {
                          return (
                            <div
                              className="singleTariff"
                              style={{ textTransform: "capitalize" }}
                              onClick={(e) => Description(e, singleTariff)}
                            >
                              {singleTariff.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="approved">
                    <div className="qtyNumTitle">No of days/Qty:</div>
                    <input
                      type="number"
                      className="charges-approved-input"
                      id="charges"
                      min={0}
                      step="1"
                      {...register("qty", { valueAsNumber: true })}
                      onChange={chargesApproved}
                    />
                  </div>
                  <div className="unitPriceWrapper">
                    <div className="unitPriceTitle">Unit price:</div>
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"N"}
                      className="charges-approved-input"
                      value={options}
                      disabled
                    />
                  </div>
                  <div className="amount">
                    <div className="chargesSentTitle">Charges Sent:</div>
                    <NumberFormat
                      thousandSeparator={true}
                      prefix={"N"}
                      className="charges charges-approved-input"
                      id="approved-charges"
                      {...register("chargesSent")}
                      value={amountCalc}
                      disabled
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="btnn1"
                  onClick={handleShowTable}
                >
                  Add Up
                </button>
              </div>
            </form>
          </div>
          {/* <div className="small-table">
            <h2>
              Claims Treatment For:{" "}
              {option
                ? option.surname ||
                  option.name ||
                  option.fullName ||
                  option.name
                : ""}
            </h2>
            <h2>EnroleeNumber: {option.employeeNo}</h2>
            <h2>
              Total Amount Billed:{" "}
              <NumberFormat
                value={totalBilledAmount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"NGN"}
              />
            </h2>
          </div> */}
          {showTable && claimsList.length > 0 && (
            <div className="claimsTableWrapper">
              <table className="submittedListStyledTable">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Diagnosis</th>
                    <th>Consultancy Date</th>

                    <th>Unit Price</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {claimsList.map((data, index) => (
                  <tbody className="size">
                    <tr key={index}>
                      <td>{data.description}</td>
                      <td>{data.diagnosis}</td>
                      <td>{data.consultancyDate.substring(0, 10)}</td>

                      <td>{data.unitPrice}</td>
                      <td>{data.qty}</td>
                      <td>{data.chargesSent}</td>
                      <td onClick={() => handleDeleteClaim(data)}>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="userListDelete"
                        />
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          )}
          {showTable && claimsList.length > 0 && (
            <button
              type="submit"
              onClick={onSubmit}
              className="btnn1"
              disabled={submitClaimButtonStatus}
            >
              Submit
            </button>
          )}
        </section>
      </div>
    </>
  );
}

export default Claims;

