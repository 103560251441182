import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function AutoComplete(props) {
  const {
    data,
    title,
    setselectedName,
    setselectedNo,
    setDesc,
    setEnroleeData,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  // const loading = true
  // const loading = open && options.length === 0
  const loading = false;

  //   FUNCTION TO SET SELECTED VALUES
  const handleSelectedValue = (e, option) => {
    if (title === "Enrolee Name or Number") {
      // console.log(`https://www.firsthmo.com/Pics/${option.imageFileName}`)

      setselectedName(
        option?.surname +
          "  " +
          (option?.name
            ? option?.name?.toLowerCase()
            : option?.fullName?.toLowerCase())
      );

      setselectedNo(option?.employeeNo);
      setEnroleeData(option);
    }

    if (title === "Diagnosis") {
      setDesc(option?.diagnosis);
    }
  };

  //   FUNCTION TO SET OPTION LABELS
  const setOptionLabel = (option) => {
    if (title === "Enrolee Name or Number") {
      return (
        option?.surname +
        "  " +
        (option?.name
          ? option?.name?.toLowerCase()
          : option?.fullName?.toLowerCase()) +
        "  " +
        option?.employeeNo
      );
    }
    if (title === "Diagnosis") {
      return option?.diagnosis;
    }
    if (title === "Tariff") {
      return option?.description;
    }
  };
  React.useEffect(() => {
    let active = true;
    // if (!loading) {
    //   return undefined
    // }

    (async () => {
      // await sleep(1e2) // For demo purposes.

      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, data]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([])
  //   }
  // }, [open])

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option?.fullName === value?.fullName
      }
      getOptionLabel={(option) => setOptionLabel(option)}
      options={options}
      loading={loading}
      onChange={(e, option) => handleSelectedValue(e, option)}
      size={"small"}
      sx={{
        width: 500,
        marginBottom: 2,
        "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
          fontSize: 14,
        },
      }}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label={title}
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

