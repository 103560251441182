import { createSlice } from '@reduxjs/toolkit'

const initialState = {

  tariffs:[],
  paginatedTariff:1
}

export const tariffSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    accumulateTariff: (state, action) => {
      state.tariffs = [...state.tariffs,action.payload]
      state.paginatedTariff += 1
      console.log(action);
    },
  },
})

export const { accumulateTariff } = tariffSlice.actions

export default tariffSlice.reducer
